<template>
    <div>
        <div class="icon-bg">
            <b-icon 
                :icon="`${icon} metric-icon`"
                size="is-large">
            </b-icon>
        </div>

        <p class="metric-label is-primary-text">{{ value }}</p>
        <p class="is-size-6">{{ name }}</p>
    </div>
</template>

<script>

export default {
  name: "MetricsBlock",
  props: {
    icon: String,
    value: String,
    name: String
  }
};

</script>

<style>

.metric-icon {
    color: white !important;
}

.metric-label {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 8px !important;
}

</style>