<template>
  <div>
    <Navbar />

    <svg style="height: 0vh">
        <defs>
            <marker id="m-end" markerWidth="10" markerHeight="10" refX="19" refY="3" orient="auto" markerUnits="strokeWidth" >
                <path d="M0,0 L0,6 L9,3 z"></path>
            </marker>
        </defs>
    </svg>

    <div class="container is-fullhd head-container">
        <section class="section head-section">
            <div class="columns">
                <div class="column is-12-tablet is-6-desktop">
                    <h1 class="title is-size-1">Catch Me If You Can: Mapping EU Company Mobility & Abuse-Detection</h1>

                    <p class="is-size-5">
                        Financial crimes committed across borders by companies and other entities in the EU are becoming increasingly difficult to detect and prevent. We are building a Knowledge Graph to help tell a connected story about EU corporate mobility from disconnected data sources.
                    </p>

                    <br />

                    <a href="#project-section">
                        <b-button type="action-button call-to-action">
                            How did we do this?
                        </b-button>
                    </a>
                    <br><br>

<blockquote>
 <p class="is-size-5"><q>Company law is about the complex interaction of rules about competitiveness, governance, stakeholder protection, taxation, anti-fraud instruments, and workers’ rights. This project delivers exactly what we need: an empirical basis to begin to understand the social impact of cross-border company mobility</q></p>
 <div class="source is-size-5">Marcus Meyer-Erdmann, the ETUI</div>
</blockquote>

                    <d3-network
                        class="is-hidden-desktop"
                        ref="relationship-network"
                        :net-nodes="this.graph.nodes"
                        :net-links="this.graph.links"
                        :options="graphOptions"
                        :link-cb="customizeLink" />
                </div>

                <div class="column">
                    <d3-network
                        class="is-hidden-touch"
                        ref="relationship-network"
                        :net-nodes="this.graph.nodes"
                        :net-links="this.graph.links"
                        :options="graphOptions"
                        :link-cb="customizeLink" />
                </div>
            </div>
        </section>
    </div>

    <hr class="head-separator" />

    <div class="container is-fullhd">
        <section class="section">
            <div class="columns metrics-container">
                <div class="column metric-column">
                    <metrics-block
                        icon="timer-outline"
                        value="2001-2020"
                        name="Data Timespan" />
                </div>

                <div class="column metric-column">
                    <metrics-block
                        icon="credit-card-outline"
                        value="15000"
                        name="Transactions Collected" />
                </div>

                <div class="column metric-column">
                    <metrics-block
                        icon="domain"
                        value="18000+"
                        name="Companies Involved" />
                </div>

                <div class="column metric-column">
                    <metrics-block
                        icon="earth"
                        value="56"
                        name="Countries Involved" />
                </div>

                <div class="column metric-column">
                    <metrics-block
                        icon="earth"
                        value="27"
                        name="EU Countries involved" />
                </div>
            </div>
        </section>

        <section class="section" id="project-section">
            <h2 class="title is-size-2">The Project</h2>
            <p>
                Financial crimes such as money laundering, social security fraud and tax evasion committed across borders by companies and other entities in the EU are becoming increasingly difficult to detect and prevent due to a lack of oversight and information about corporate behavior. This problem costs EU and national budgets on average €110 billion each year. <br/><br/>

                The problem is that there are no existing databases that integrate relevant information related to companies and their mobility across borders in the EU. This information can include: who the ultimate owners and stakeholders of EU companies are; how many employees do these companies have before and after cross-border mergers and divisions; and what their annual financial statements look like. <br/><br/>
                
                We are building a Knowledge Graph of information concerning EU company mobility for our civil society partner, the European Trade Union Institute (ETUI), in the interests of safeguarding EU employee rights and citizen well-being. This graph is intended to drive future Artificial Intelligence (AI) assisted decision-support systems used by civil society organisations such as the ETUI to detect and prevent corporate abuse of regulations reducing negative social and financial consequences for citizens and employees. <br/><br/>

                The result is an enormously valuable Knowledge Graph because it helps to tell a connected story about EU corporate mobility from disconnected data sources which are continually updated.
            </p>

            <div class="tools-container">
                <div class="columns">
                    <div class="column">
                        <project-card
                            icon="marker"
                            title="Text Annotation Platform"
                            otherLink="https://drive.google.com/file/d/1kn6zUYeCqCRh6AOgvo6RLsowOwf7bRT4/view"
                            otherLinkLabel="Download">
                            <p>
                                We designed and developed an extension to the open-source collaborative text annotation tool - Hypothes.is. This customized annotation tool has been built keeping in mind the needs of social scientists (including legal researchers) and data scientists working with a variety of textual sources. In the NWA project, we used this tool to capture information about EU corporate cross-border transactions in PDF legal documents.
                                <ul>
                                    <li><a href="https://github.com/nwa-catch-me-if-you-can-project/client">Source code</a></li>
                                    <li><a href="https://docs.google.com/document/d/18t1icQE1AM4sYd37fSlJwRkviK9416notk6QFkGxrps/edit?usp=sharing">Installation instructions: Windows</a></li>
                                    <li><a href="https://docs.google.com/document/d/1WOQL3R_opiqtZqJifJJSWMqvXv9LYbEv_zz4qahd3JE/edit?usp=sharing">Installation instructions: MacOS</a></li>
                                </ul>
                            </p>

                        </project-card>
                    </div>

                    <div class="column">
                        <project-card
                            icon="gavel"
                            title="Ontology"
                            githubLink="https://github.com/MaastrichtU-IDS/cbcm-ontology">
                            <p>
                                We have created an ontology of standard terminology concerning EU corporate law and corporate mobility. These terms are used to define entities and relations appearing in the corporate mobility Knowledge Graph. More information about the ontology can be browsed at the link below
                            </p>
                        </project-card>
                    </div>

                    <div class="column">
                        <project-card
                            icon="graphql"
                            title="Knowledge Graph"
                            otherLinkLabel="Get Access"
                            :otherLinkCallback="openKGLink">
                            <p>
                                We have developed a Knowledge Graph of cross-border company transactions across the EU. This graph is meant to serve as a resource for AI-powered query, analysis and reasoning software platforms. The graph itself is proprietary and the curation thereof is funded by the European Trade Union Institute (ETUI). To inquire about possibilities for obtaining access to the data, please contact <a v-on:click="openKGLink()">Marcus Meyer-Erdmann</a>
                            </p>
                        </project-card>
                    </div>
                </div>
            </div>
        </section>

        <section class="section" id="publications-section">
            <h2 class="title is-size-2">Outputs</h2>
            <h3 class="title is-size-4"> Software </h3>
            <a href="https://github.com/nwa-catch-me-if-you-can-project">NWA catch me if you can project Github page</a>
            <br><br>
            <h3 class="title is-size-4"> Text annotation workshop </h3>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/d2IWVlHXn6s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br><br>
            <h3 class="title is-size-4"> Project findings presentation </h3>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/YGEZKtHFDss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br />

            <div class="columns">
                <div class="column is-3">
                </div>

                <div class="column is-3">
                </div>

                <div class="column">
                </div>
            </div>
        </section>

        <section class="section" id="team-section">
            <h2 class="title is-size-2">Meet The Team</h2>

            <p>
                <b>Catch Me If You Can</b> was possible thanks to the collaboration between the
                <a href="https://www.maastrichtuniversity.nl/research/institute-data-science" target="_blank" rel="noopener">Maastrict University Istitute of Data Science</a>
                , the <a href="https://www.maastrichtuniversity.nl/about-um/faculties/law/research/law-and-tech-lab" target="_blank" rel="noopener">Maastrict University Law & Tech lab</a>,
                <a href="https://www.etui.org/" target="_blank" rel="noopener">ETUI</a>
                and <a href="https://risksearch.eu/#home" target="_blank" rel="noopener">RiskSearch BV</a>
            </p>

            <div class="container">
                <div class="partners-container">
                    <a href="https://www.maastrichtuniversity.nl/research/institute-data-science" target="_blank" rel="noopener">
                        <img
                            class="partner-img"
                            src="@/assets/partners/ids.png"
                            alt="Maastricht University Law and Tech lab">
                    </a>

                    <a href="https://www.maastrichtuniversity.nl/about-um/faculties/law/research/law-and-tech-lab" target="_blank" rel="noopener">
                        <img
                            class="partner-img"
                            src="@/assets/partners/law_tech.jpg"
                            alt="Maastricht University Law and Tech lab">
                    </a>

                    <a href="https://risksearch.eu/#home" target="_blank" rel="noopener">
                        <img
                            class="partner-img"
                            src="@/assets/partners/risksearch.jpg"
                            alt="Maastricht University Law and Tech lab">
                    </a>

                    <a href="https://www.etui.org/" target="_blank" rel="noopener">
                        <img
                            class="partner-img"
                            src="@/assets/partners/etui.jpg"
                            alt="Maastricht University Law and Tech lab">
                    </a>
                </div>
            </div>

            <br />

            <person
                name="Kody Moodley"
                pictureName="kody.jpg"
                linkedin="https://www.linkedin.com/in/kody-moodley"
                website="https://sites.google.com/site/kodymoodley"
                email="U2FsdGVkX1/Uipm7HaoppV3E6eQJqGePCryxbpx2CpJ353Nr9iyLIZ/u4WwSBWasiCcCwa27b7zF7WmqZC/wQw==">
                <template v-slot:affiliation>
                    <a href="https://www.maastrichtuniversity.nl/research/ids/contact" target="_blank" rel="noopener">Institute of Data Science</a> /
                    <a href="https://www.maastrichtuniversity.nl/about-um/faculties/law/research/law-and-tech-lab" target="_blank" rel="noopener">Maastricht Law and Tech Lab</a>
                </template>

                <template v-slot:bio>
                    <p>
                        Kody Moodley is an Assistant Professor at the Institute of Data Science at Maastricht University. He completed his PhD in ontologies and automated reasoning at the University of Manchester and the University of KwaZulu-Natal. His research applies Ontologies, Semantic Web technologies, Knowledge Graphs and FAIR data management to develop computational legal decision-making solutions. He is a FAIR embassador at Maastricht University and co-organises the UM Data Science Research Seminar Series.
                    </p>
                </template>
            </person>

            <person
                id="#marcus"
                name="Marcus Meyer-Erdmann"
                pictureName="marcus.jpg"
                linkedin="https://nl.linkedin.com/in/meyermarcus"
                website="https://www.etui.org/about-etui/staff/marcus-meyer">
                <template v-slot:affiliation>
                    <a href="https://www.etui.org/" target="_blank" rel="noopener">European Trade Union Institute</a>
                </template>

                <template v-slot:bio>
                    <p>
                        Marcus Meyer-Erdmann is a Senior Researcher at the European Trade Union Institute - the research arm of the European Trade Union Confederation. His research interests cover employee participation issues, both under EU and national law, as well as corporate mobility issues.
                    </p>
                </template>
            </person>

            <person
                name="Rūta Liepiņa"
                pictureName="ruta.jpeg"
                website="https://www.maastrichtuniversity.nl/p70067150">
                <template v-slot:affiliation>
                    <a href="https://www.maastrichtuniversity.nl/about-um/faculties/law/research/law-and-tech-lab" target="_blank" rel="noopener">Maastricht Law and Tech Lab</a>  / <a href="https://www.maastrichtuniversity.nl/M-EPLI" target="_blank" rel="noopener">M-EPLI</a>
                </template>

                <template v-slot:bio>
                    <p>
                        Rūta Liepiņa is an Assistant Professor in Digital Legal Studies at the Faculty of Law, Maastricht University. In the NWA project she works on the legal annotation schema and text annotation experiments. She is a member of the <a href="https://www.maastrichtuniversity.nl/about-um/faculties/law/research/law-and-tech-lab" target="_blank" rel="noopener">Maastricht Law and Tech Lab</a> and <a href="https://www.maastrichtuniversity.nl/M-EPLI" target="_blank" rel="noopener">M-EPLI</a>.
                    </p>

                    <br/>

                    <p>
                        Her research interests revolve around the topics of legal reasoning, argumentation, and data science. In particular, she analyses patterns in decision-making by judges and lawyers by using AI and law methods from the fields of logic, argumentation, natural language processing, ontologies, data science, and computational social science. She is also interested in the topics of AI liability in torts, consumer protection through technology, and mass collaborations in legal research.
                    </p>
                </template>
            </person>

            <person
                name="Pedro V. Hernández Serrano"
                pictureName="pedro.jpeg"
                website="https://www.maastrichtuniversity.nl/p.hernandezserrano">

                <template v-slot:affiliation>
                    <a href="https://www.maastrichtuniversity.nl/research/ids/contact" target="_blank" rel="noopener">Institute of Data Science</a> / <a href="https://www.maastrichtuniversity.nl/about-um/faculties/law/research/law-and-tech-lab" target="_blank" rel="noopener">Maastricht Law and Tech Lab</a>
                </template>

                <template v-slot:bio>
                    <p>
                        Pedro is the first applied Data Scientist at the <a href="https://www.maastrichtuniversity.nl/research/ids/contact" target="_blank" rel="noopener">Institute of Data Science</a>. He specializes in developing end to end Data Products for research since 2017. Pedro has been the Data Administrator of the CbCM database and his role in this project is the development of a REST API for data protocol access, usage and license, contributing to accomplishing the FAIR principles implementation on this research. Pedro's research interests are Data Architecture, Network Analysis, Legal Informatics and Quantitative Methods.
                    </p>
                </template>
            </person>

            <person
                name="Diana Mitroi"
                pictureName="diana.png"
                linkedin="https://www.linkedin.com/in/dianamitroi/"
                phone="U2FsdGVkX18qG9DRxc1ZAdaCsJ2Lfp9vY4VblEiBTRo="
                email="U2FsdGVkX19Y8cQ81hAq7EU0Qj84/3LZ2l9WVHOuzfVltssh+7r7X5cN4EDCNOlZ">
                    <template v-slot:affiliation>
                        Independent Contractor
                    </template>

                    <template v-slot:bio>
                        <p>
                            Diana Mitroi is an experienced fintech expert specialised in B2B and B2B2C project development. She was project manager for fintech projects developed with Brightlands, KvK, Risksearch and several insurance companies. <br/>

                            She focuses on product development, UX, BA and ML/AI algorithms for fintech/insurtech industries. She was awarded 1st Prize for the International Impact track at BrightHack 2018 and contributed to various projects in areas such as digital insurance, digital KYC and research.
                            <br/>
                            She is currently leading the European Cyber Underwriting Department at Cyber Lev.
                        </p>
                    </template>
            </person>

            <person
                name="Cristiana Filip"
                pictureName="cristiana.png"
                email="U2FsdGVkX1+4BJzntP0bxjZJc7CMoCbiQRE1wKSzyhZvdv/S4DyS2zbi4ttOLyPd">
                <template v-slot:affiliation>
                    Independent Contractor
                </template>

                <template v-slot:bio>
                    <p>
                        Cristiana is a Front-end developer (UI /UX) for Fintech. She participated in projects with RiskSearch, KVK, Brightlands, Dela, and Maastricht University. She was awarded the 3rd prize in the Hack4SmartServices hackathon "Unlocking Society - returning to the new normal".
                    </p>
                </template>
            </person>

            <person
                name="Alex Olteanu"
                pictureName="alex.png"
                linkedin="https://www.linkedin.com/in/olteanu-alexandru/">
                <template v-slot:affiliation>
                    Independent Contractor
                </template>

                <template v-slot:bio>
                    <p>
                        Alex is a Software Engineer and in this project he designed an easy to use interface for annotating legal documents, using React. Together with Cristiana and Kody he's eager to see how end users will employ the tool for their projects.
                    </p>
                </template>
            </person>

            <person
                name="Aline Hoffmann"
                pictureName="aline.jpg"
                website="https://www.etui.org/about-etui/staff/aline-hoffmann-0">
                <template v-slot:affiliation>
                    <a href="https://www.etui.org/" target="_blank" rel="noopener">ETUI</a>
                </template>

                <template v-slot:bio>
                    <p>
                        Aline Hoffmann joined the ETUI in October 2012. The theme of European workers’ participation has long marked her academic and trade union career. After completing her BA in political economy at the University of California at Berkeley in 1993, she worked at the Wissenschaftszentrum Berlin (WZB). She then took a Masters degree in European Studies at the University of Cambridge, followed by a PhD from the University of Warwick. In her undergraduate thesis, her Masters thesis and her doctoral research, she explored a wide range of legislative, conceptual, political and practical aspects of employee interest representation from a European comparative perspective. From 2001 until 2012 she was head of the European Works Councils Team at the headquarters of the German metalworkers union IG Metall, working also on related issues of union policy towards central and group works councils as well as supervisory board representation. For 10 years she represented the IG Metall at the Company Policy Committee of what was then the European Metalworkers Federation (EMF). She was the national officer charged with coordinating IG Metall and EMF activity within the central, group and European works councils of the Bosch Group. She was a member of the Supervisory Board at Bosch for 5 years. She is also the coordinator of the European Workers’ Participation Competence Centre (EWPCC).
                    </p>
                </template>
            </person>

            <person
                name="Tobias Breuer"
                pictureName="tobias.jpeg"
                linkedin="https://www.linkedin.com/in/tobias-breuer/">
                <template v-slot:affiliation>
                    Student Assistant
                </template>

                <template v-slot:bio>
                    <p>
                        Tobias is currently assisting Rūta Liepiņa with the annotation pipeline by checking for potential problems with annotating documents and extracting annotations, and other things that come up. He is a third-year student of Maastricht University's BSc program in Econometrics & Operations Research, and he is going to pursue a master program in Operations Research next year.
                    </p>
                </template>
            </person>

            <person
                name="Walter Simoncini"
                pictureName="walter.jpg"
                website="https://blog.ashita.nl/"
                linkedin="https://www.linkedin.com/in/walter-simoncini-a77508137/"
                email="U2FsdGVkX189cEazjkc1wyggCGu3GRMT9H4W9KelCxE=">
                <template v-slot:affiliation>
                    Student Assistant
                </template>

                <template v-slot:bio>
                    <p>
                        Walter is a Software Engineer and aspiring Data Scientist, currently pursuing a BSc in Data Science and Artificial Intelligence at Maastricht University. Walter is contributing to the development of the CbCM ontology and is creating the data pipeline to populate and maintain the Knowledge Graph.
                    </p>
                </template>
            </person>
        </section>
    </div>

    <footer class="footer">
        <hr />

        <div class="content has-text-centered">
            <div class="columns">
                <div class="column is-3">
                </div>
                <div class="column">
                    <img
                        class="footer-logo"
                        src="@/assets/logo.png"
                        alt="NWO Catch Me If You Can logo">
                </div>
                <div class="column footer-right">
                    <p class="footer-text">This project was made possible thanks to the <a href="https://www.nwo.nl/">NWO Idea Generator (NWA-IDG)</a> grant number: NWA.1228.192.285</p>
                </div>
                <div class="column is-3">
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>

import D3Network from 'vue-d3-network'

import Navbar from "@/components/Navbar.vue";
import MetricsBlock from "@/components/MetricsBlock.vue";
import ProjectCard from "@/components/ProjectCard.vue";
import Person from "@/components/Person.vue";
import mockGraph from '@/assets/demo-graph.json'

var cryptojs = require("crypto-js");

export default {
  name: 'App',
  components: {
    Navbar,
    Person,
    D3Network,
    ProjectCard,
    MetricsBlock
  },
  metaInfo: {
    title: "Catch Me If You Can: Mapping EU Company Mobility & Abuse-Detection",
    titleTemplate: "%s",
    htmlAttrs: {
        lang: "en",
        amp: true
    },
    meta: [
        {
            name: "description",
            content: "Accusamus odio fugiat voluptatibus, asperiores maxime exercitationem voluptas."
        }
    ]
  },
  data: function () {
      return {
        graph: {
            nodes: [],
            links: []
        },
        graphOptions: {
            nodeSize: 50,
            nodeLabels: true,
            linkLabels:true,
            force: 10000,
            linkWidth: 2
        }
    }
  },
  created () {
      this.graph = mockGraph;
  },
  mounted () {
      // Shift back the arrow labels to avoid them being covered by
      // the nodes
      let textPaths = document.getElementsByTagName("textPath");

      for (let i = 0; i < textPaths.length; i++) {
          textPaths[i].setAttribute("startOffset", "15%");
      }
  },
  methods: {
    customizeLink(link) {
        link._svgAttrs = {
            // Add the termination arrow
            "marker-end": "url(#m-end)"
        }

        return link
    },
    openKGLink () {
        var bytes = cryptojs.AES.decrypt(
            "U2FsdGVkX1/p7xmaJIjugcr6MvsMEhZDG/RaRcs/0w0=",
            process.env.VUE_APP_EMAIL_KEY);

        var decryptedEmail = bytes.toString(cryptojs.enc.Utf8);
        this.$window.open(`mailto:${decryptedEmail}`)
    }
  }
}

</script>

<style>

.icon-bg {
  background-color: #3E77D7 !important;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  text-align: center;
  margin-bottom: 32px;
  margin: auto;
  margin-bottom: 16px;
}

.icon-bg .icon {
  height: 100% !important;
}

.metric-column {
  text-align: center;
}

.is-primary {
  background-color: #3E77D7 !important;
}

.is-primary-text {
  color: #3E77D7 !important;
}

.researcher-name {
    font-weight: bold !important;
    margin-bottom: 8px !important;
}

.researcher-social-icons {
    margin-top: 8px;
    margin-bottom: 0px;
}

.researcher-social-icons span {
    margin-right: 6px;
}

hr {
    margin: 0.5rem !important;
}

.researcher-image {
    border-radius: 50%;

    border: 8px solid white;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
}

.action-button {
    font-size: 20px !important;
    padding: 0px 32px 0px 32px !important;

    color: white !important;
    border: 0px !important;
}

.call-to-action {
    background: #f64f59; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f64f59, #f8727a); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f64f59, #f8727a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.metrics-container {
    margin-top: 0px !important;
}

.head-separator {
    width: 100% !important;
    margin: 0px !important;
}

.head-container {
    margin-bottom: 64px;
}

.graph-node-label {
    font-weight: bold;
    font-size: 12px;
}

.link-label {
    transform: translate(-5px, -5px);
}

.link {
    stroke: #E5E5E5;
}

#m-end path {
  fill: #E5E5E5;
}

.footer-logo {
    width: 75px;
    height: 75px;
}

.footer-text {
    text-align: left;
}

.footer {
    background-color: white !important;
    padding-bottom: 0px !important;
}

.footer-right {
    margin: auto;
}

.tools-container {
    margin-top: 32px;
    text-align: center;
}

blockquote {
 margin: 0;
 padding: 0;
 color: #555;
}

blockquote p {
 font: italic 1em Georgia, Times, "Times New Roman", serif; 
 font-size: 1em;
 margin: 1.5em 2em 0 1.5em;
 padding: 0;
}
blockquote .source {
 text-align: right;
 font-style: normal;
 margin-right: 2em;
}

.partner-img {
    height: 216px;
    margin-left: 32px;
    margin-right: 32px;
}

.partners-header {
    text-align: center;
}

.partners-container {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
}

.net-svg {
    overflow: visible;
}

.email-span {
    font-weight: normal;
    color: #3E77D7;
}

.email-span:hover {
    text-decoration: underline;
}

</style>