<template>
    <div class="card project-card-container">
        <header class="card-header">
            <b-icon :icon="`${icon} proj-icon`" size="is-medium"></b-icon>
            <h4 class="title is-size-5 project-title">
                {{ title }}
            </h4>
        </header>
        <div class="card-content">
            <div class="content project-description">
                <slot></slot>
            </div>
        </div>
        <footer class="card-footer">
            <a
                class="action-link"
                :href="githubLink"
                v-if="githubLink != null"
                target="_blank"
                rel="noopener">
                    <b-button type="action-button proj-button github-button">
                        <b-icon class="github-button-icon" icon="github"></b-icon>
                        <p class="github-btn-label">See on GitHub</p>
                    </b-button>
            </a>

            <a
                class="action-link"
                :href="otherLink"
                v-if="otherLink != null">
                <b-button type="action-button proj-button call-to-action">
                    {{ otherLinkLabel }}
                </b-button>
            </a>

            <a
                class="action-link"
                :href="otherLink"
                v-on:click="otherLinkCallback()"
                v-if="otherLinkCallback != null">
                <b-button type="action-button proj-button call-to-action">
                    {{ otherLinkLabel }}
                </b-button>
            </a>
        </footer>
    </div>
</template>

<script>

export default {
  name: "ProjectCard",
  props: {
      icon: String,
      title: String,
      githubLink: String,
      otherLink: String,
      otherLinkLabel: String,
      otherLinkCallback: Function
  }
};

</script>

<style>

.proj-icon {
    margin-left: 16px !important;
    color: white !important;
}

.card-header {
    padding: 16px;

    background: #3E77D7; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3E77D7, #6592df); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3E77D7, #6592df); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    box-shadow: none !important;
}

.project-title {
    color: white !important;
    margin-top: 5px;
    margin-left: 24px;
}

.content {
    text-align: left;
}

.proj-button {
    width: 100%;
}

.github-button {
    background-color: black !important;

    background: #000000; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #000000, #333333); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #000000, #333333); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.github-button-icon {
    margin-right: 4px !important;
}

.github-btn-label {
    display: inline;
}

.project-card-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.project-card-container .card-content {
  flex: auto;
}

.project-description {
    min-height: 96px;
}

.action-link {
    width: 100%;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
}

.card-footer {
    border-top: none !important;
}

</style>