<template>
    <b-navbar :spaced="true">
        <template #brand>
            <b-navbar-item>
                <img
                    class="project-logo"
                    src="@/assets/logo.png"
                    alt="NWO Catch Me If You Can logo">
            </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item href="#project-section">
                The Project
            </b-navbar-item>
            <b-navbar-item href="#publications-section">
                Outputs
            </b-navbar-item>
            <b-navbar-item href="#team-section">
                Meet The Team
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>

export default {
  name: 'Navbar',
  components: {}
}

</script>

<style scoped>

.project-logo {
    width: 100px;
    height: 100px;
    max-height: 12rem !important;
}

</style>
