<template>
    <div class="columns person-container">
        <div class="column is-2">
            <img
            class="researcher-image"
            :src="getImageURL(pictureName)"
            :alt="`${name} picture`">
        </div>
        <div class="column">
            <p class="subtitle is-size-5 researcher-name">{{ name }}</p>
            <p class="is-size-6 researcher-affiliation">
                <slot name="affiliation"></slot>
            </p>

            <div class="researcher-social-icons">
                <a v-if="website != null" :href="website" target="_blank" rel="noopener" class="personal-link">
                    <b-icon icon="web"></b-icon>
                </a>

                <a v-if="linkedin != null" :href="linkedin" target="_blank" rel="noopener" class="personal-link">
                    <b-icon icon="linkedin"></b-icon>
                </a>

                <a v-if="github != null" :href="github" target="_blank" rel="noopener" class="personal-link">
                    <b-icon icon="github"></b-icon>
                </a>

                <a v-if="email != null" v-on:click="openEmailLink()" class="personal-link">
                    <b-icon icon="email"></b-icon>
                </a>

                <a v-if="phone != null" v-on:click="openPhoneLink()" class="personal-link">
                    <b-icon icon="phone"></b-icon>
                </a>
            </div>

            <hr />

            <slot name="bio"></slot>
        </div>
    </div>
</template>

<script>

var cryptojs = require("crypto-js");

export default {
  name: "Person",
  props: {
    name: String,
    pictureName: String,
    affiliation: String,
    website: String,
    github: String,
    linkedin: String,
    email: String,
    phone: String
  },
  methods: {
    getImageURL (pictureName) {
        return require(`@/assets/team/${pictureName}`);
    },
    openEmailLink () {
        if (this.email == null) return;

        var decryptedEmail = this.decrypt(this.email);
        this.$window.open(`mailto:${decryptedEmail}`)
    },
    openPhoneLink () {
        if (this.phone == null) return;

        var decryptedPhone = this.decrypt(this.phone);
        this.$window.open(`tel:${decryptedPhone}`);
    },
    decrypt (inputText) {
        var bytes = cryptojs.AES.decrypt(inputText, process.env.VUE_APP_EMAIL_KEY);
        return bytes.toString(cryptojs.enc.Utf8);
    }
  }
};

</script>

<style>

.personal-link {
    color: #4a4a4a;
}

.personal-link:hover {
    color: $app-color;
}

.person-container {
    margin-bottom: 32px !important;
}

</style>