import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.config.productionTip = false;

Vue.use(Buefy);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Vue.prototype.$window = window;

new Vue({
  render: h => h(App),
}).$mount('#app')
